/**
  * See: https://api.rubyonrails.org/classes/ActionController/Flash/ClassMethods.html
  * Default flash keys are alert and notice
  *
  * TODO: move the colors to _colors.css.scss
  */
.flash {
  display: block;

  .alert {
    h3 {
      margin-top: 0;
      color: #444;
    }

    background-color: #fff0eb;
    border-color: #ffd599;
    color: rgb(155, 0, 40);
  }

  .notice {
    h3 {
      margin-top: 0;
      color: #444;
    }

    background-color: #f1ffeb;
    border-color: #a6d98c;
    color: #203913;
  }

  li {
    border-width: 1px;
    border-radius: 1px;
    display: block;
    font-size: 16px;
    line-height: 26px;
    margin: 0;
    padding: 10px;

    .close {
      font-size: 11px;
      float: right;
    }
  }
}
