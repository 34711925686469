.modal {
  user-select: initial;
  display: none;

  &[data-visible='true'] {
    display: block;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 8000;
  }

  .dialog {
    background-color: #fff;
    border: 1px solid #bbb;
    box-shadow: 0 1px 2px rgba(0,0,0,0.5);
    max-height: calc(100% - 100px);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: scroll;
  }

  .modal-actions {
    text-align: right;

    .secondary-button {
      width: 40px;
      padding: 0 0 3px 0;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 3rem;
      background-image: none;
      background-color: transparent;
      box-shadow: none;
      color: #4c4c4c;
      border: none;
      cursor: pointer;
      font: inherit;
      &:hover {
        color: $primary-color;
      }
    }
  }

  .column {
    width: 50%;
    display: inline-block;
    padding: 40px;
    .sign-up-details p{
      margin: 0 0 25px 0;
      max-width: 500px;
    }
    .sign-up-details h4{
      font-weight: bold;
    }
    .sign-up-details .second-p {
      margin: 5px 0 0 0;
    }
  }

  .telephone-number {
    color: $primary-color;
    font-weight: bold;
    font-size: 1rem;
  }

  .ss-icon.ss-phone {
    font-size: 0.8rem;
  }

  .column.right {
    background-color: #f5f5f3;
    table, tr, td {
      width: 100%;
    }
  }

  .lead-contact-form {
    height: 40px;
    width: 100%;
    margin-bottom: 15px;
  }

  .lead-services {
    margin: 4px;
  }

  .submit-lead-contact-form {
    margin-top: 15px;
  }

  input::placeholder {
    font-size: 1rem;
    overflow: visible;
  }

  tr td input.button { float: right;}

  .confirmation {
    padding: 40px;
    h3, p {
      text-align: center;
    }
    p { margin-top: 15px}
  }
}

.car-select-list {
  padding: 40px 30px 20px 30px;
  p {
    margin-bottom: 20px;

    .vin {
      background-color: #eee;
      font-family: monospace;
      letter-spacing: 0.05em;
      padding: 4px;
    }
  }

  ul {
    background-color: $secondary-color-light;;
    border: 1px solid $medium-grey;
    list-style: none;
    max-height: 159px;
    overflow-y: auto;
    padding: 0;

    li {
      border-bottom: 1px solid $medium-grey;
      &:last-child { border-bottom: 0; }
    }

    a {
      display: block;
      font-size: larger;
      padding: 8px;
      text-decoration: none;
      background-color: $secondary-color-light;
      &:hover {
        color: $primary-color;
      }
    }
    a:nth-of-type(even) {
      background-color: $secondary-color-light-alt;
    }
  }
}

@media (max-width: 1150px) {
  .modal {
    .column {
      width: 100%;
      display: block;
      padding: 40px;
      .medium-header { text-align: center;}
      .sign-up-details p{
        text-align: center;
      }
      .sign-up-details h4{
        text-align: center;
      }
      .sign-up-details .second-p {
        text-align: center;
      }
    }
    .telephone-number {
      text-align: center;
      display: block;
    }
    .ss-icon.ss-phone {
      text-align: center;
    }
    tr td input.button {
      float: none;
      display: block;
      margin: auto;
    }
  }
}

@media (max-width: 480px) {
  .modal .dialog {
    font-size: smaller;
    overflow: auto;
    max-height: calc(100% - 50px);
  }
}
