/* main colors */
$ox-blood: rgb(204, 0, 51);
$light-rose: hsl(355, 27%, 91%);
$grey-brown: rgb(76, 76, 76);
$medium-grey: hsl(0, 6%, 65%);
$warm-light-grey: hsl(60, 5%, 96%);
$warm-lightest-grey: hsl(60, 20%, 99%);
$error-color: rgb(204, 0, 51);

/* shadow colors */
$box-shadow-color: rgba(0, 0, 0, 0.5);

/* modular colors  */
$primary-color: $ox-blood;
$primary-color-light: $light-rose;
$secondary-color: $grey-brown;
$secondary-color-medium: $medium-grey;
$secondary-color-light: $warm-light-grey;
$secondary-color-light-alt: $warm-lightest-grey;
$error-color: $ox-blood;
