.announcement-container {
  background-color: hsl(0, 0%, 97%);
  border: solid hsl(0, 0%, 85%);
  border-width: 2px 0;
  font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", sans-serif;
  font-size: 0.875em;
  margin: 10px 0;
  padding: 20px 0;
  box-sizing: border-box;

  &#news {
    background-color: #fff7eb;
    border-color: #ffd599;
    color: #664619;

    .announcement-content { max-width: 960px; }
  }

  &#referral {
    background-color: hsl(210, 60%, 98%);
    border-color: hsl(210, 29%, 85%);

    .announcement-content { text-align: center; }
  }
}

.announcement-content {
  @extend .page-width;
}
