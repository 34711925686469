.notification, .notification-banner {
  line-height: 1.5;
  padding: 8px;
  @extend %clearfix;

  &[data-fixed='true'] {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 8000;
    padding-top: 17px;
    padding-bottom: 17px;

    & > div { @extend .page-width; }
  }

  &[data-fixed='false'] {
    @extend .round-corners;
    padding-left: 12px;
    padding-right: 8px;
  }

  &.error {
    background-color: $error-color;
    color: #fff;

    .ss-icon { @extend .ss-alert; }
    .dismiss-banner:hover { background-color: hsla(0, 0%, 100%, 0.2); }
  }

  &.warning {
    background-color: $primary-color-light;
    color: $secondary-color;
    padding: 25px;

    .ss-icon { @extend .ss-alert; }
  }

  &.notice {
    background-color: hsl(210, 60%, 98%);
    border-color: hsl(210, 29%, 85%);
    color: hsl(210, 29%, 20%);

    .ss-icon { @extend .ss-info; }
  }

  .dismiss-banner {
    border-radius: 11px;
    color: inherit;
    font-weight: bold;
    float: right;
    text-decoration: none;
    height: 22px;
    width: 22px;
    text-align: center;
    line-height: 20px;

    &:hover { background-color: hsla(0, 0%, 0%, 0.1); }
  }

  .ss-icon {
    float: left;
    padding-right: 12px;
  }

  .content {
    font-size: smaller;
    margin-left: 28px;
  }
}

.notification-banner {
  opacity: 0;
  padding-left: 0;
  padding-right: 0;
  transition: opacity 300ms;

  &[data-visible='true'] {
    opacity: 1;
    transition: opacity 200ms;
  }
}
