@function image_path($image) {
  @return '../../../assets/images/#{$image}';
}
.page-header .enzo-main-logo {
  float: left;
}

#user-nav-container {
  float: right;
}

.user-nav {
  @extend .round-corners;
  border: none;
  display: table;
  list-style: none;
  font-size: 18px;
  text-align: right;
  margin: 0;
  padding: 12px 0;
  @extend %clearfix;

  [data-tipsy] {
    // @extend .tipsy--s;
    &:after {
      font-size: 11px;
    }
  }

  li {
    display: table-cell;
    vertical-align: middle;
  }

  a {
    color: $secondary-color;
    display: table;
    text-decoration: none;
    height: 44px;

    &:hover {
      color: $primary-color;
    }
  }

  .cell {
    display: table-cell;
    padding: 0 10px;
    vertical-align: middle;
  }

  .user-info .cell:last-child {
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .shop-name {
    color: $secondary-color;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    text-align: right;
  }

  .moto-visuals-icon {
    height: 20px;
    width: 36px;
    background-image: url(image_path('MotoVisuals_icon.svg'));
  }

  .driverside-visuals-icon {
    height: 24px;
    width: 30px;
    background-image: url(image_path('RepairVisuals_icon.svg'));
    background-repeat: no-repeat;
  }

  /* TODO: delete */
  .repair-visuals-icon {
    height: 24px;
    width: 30px;
    background-image: url(image_path('RepairVisuals_icon.svg'));
    background-repeat: no-repeat;
  }

  .user-display-login {
    color: $secondary-color;
    font-size: 14px;
    font-style: italic;
    line-height: 1;
    margin-top: 4px;
    text-align: right;
  }

  .ss-icon {
    font-size: 18px;
    position: relative;
    top: 2px;
  }
}

.user-nav li:last-child a .cell {
  padding-right: 0;
}

@media (max-width: 900px) {
  .user-display-login {
    text-overflow: ellipsis;
    width: 170px;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media (max-width: 820px) {
  .page-header .enzo-main-logo {
    background: url(image_path('logo-icon.svg')) 0 50% no-repeat;
    background-size: 100%;
    width: 48px;
  }
}

@media (max-width: 770px) {
  #user-nav-container {
    float: none;
    .user-nav {
      display: flex;
      justify-content: space-between;
    }

    .trial-user-nav {
      float: right;
      display: table;
    }
  }
}

@media (max-width: 680px) {
  .user-nav .user-info .cell:last-child {
    width: auto;
  }
}

@media (max-width: 480px) {
  .user-nav .user-info .cell:first-child {
    display: none;
  }
}

.trial-user-nav {
  @extend .round-corners;
  border: 1px solid #ccc;
  font-size: 11px;
  height: 44px;

  small {
    font-size: 11px;
  }
  strong {
    font-size: 13px;
  }
}
