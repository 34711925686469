.button {
  background-color: $primary-color;
  border: 1px solid $primary-color;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  padding: 8px 10px;
  background: linear-gradient(lighten($primary-color, 5%), $primary-color);
  //newly added

  &:hover {
    background: linear-gradient(lighten($primary-color, 8%), $primary-color);
  }

  &:focus {
    outline: none;
  }

  &[disabled='true'],
  &[disabled] {
    //@extend .tipsy--s;
    color: $secondary-color-medium;

    &:after {
      width: auto;
    }
  }
}

button {
  @extend .button;
  padding: 2px 10px;
  height: 40px;
  width: 100%;
  border-radius: 3px;
}

.text-button {
  background: none;
  border: none;
  box-shadow: none;
  height: auto;
  padding: 0;
  width: auto;
}

button.primary,
.button.primary,
input[type='submit'].button.primary {
  box-shadow: 0 1px 2px $box-shadow-color;
  border: 1px solid $primary-color;
  border-radius: 0;
  color: #fff;
  background: linear-gradient(lighten($primary-color, 5%), $primary-color);
  height: 40px;
  border-radius: 3px;

  &:hover {
    background: linear-gradient(lighten($primary-color, 8%), $primary-color);
  }
}

button.secondary,
.button.secondary,
input[type='submit'].button.secondary {
  box-shadow: 0 1px 2px $box-shadow-color;
  border: 1px solid $primary-color;
  border-radius: 0;
  color: #fff;
  background: linear-gradient(lighten($primary-color, 5%), $primary-color);
  height: 40px;

  &:hover {
    background: linear-gradient(lighten($primary-color, 8%), $primary-color);
  }
}

.small-button {
  @extend .button;
  height: 40px;
  line-height: normal;
  padding: 4px;
  box-shadow: none;
  border: none;
}

.button-icon {
  @extend .ss-icon;
  position: relative;
  top: 1px;

  & ~ .label {
    margin-left: 6px;
  }
}

.segmented-button {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
  }

  a {
    @extend .button;
    color: #fff;
    display: block;
    font-size: 13px;
    padding: 5px 8px 3px;
    text-decoration: none;
    box-shadow: none;
  }

  a[data-active='false'] {
    color: #fff;
    pointer-events: none;
    border: none;

    &:hover {
      background: linear-gradient(hsl(0, 0%, 98%), hsl(0, 0%, 90%));
    }
  }

  li:first-child a {
    border-right: 1px solid #fff;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  li:last-child a {
    border-bottom: 1px solid $primary-color;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

textarea,
input[type='text'],
input[type='tel'],
input[type='email'] {
  @extend .round-corners;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #aaa;
  padding: 8px;
  box-sizing: border-box;
}

textarea {
  display: block;
  width: 100%;
  height: 120px;
}

.ui-label.required:after,
.required-label {
  color: $primary-color;
  text-transform: initial;
}

.ui-label.required:after {
  content: '*';
  vertical-align: super;
}

input[type='text'][data-has-error='true'],
textarea[data-has-error='true'] {
  border-color: $primary-color;
}

.text-field-with-indicator-container {
  position: relative;

  .text-field-indicator {
    @extend .round-corners;
    background-color: #fff;
    color: #aaa;
    cursor: pointer;
    display: none;
    font-size: 18px;
    font-weight: lighter;
    line-height: 14px;
    position: absolute;
    top: 4px;
    right: 4px;

    .down-arrow {
      font-size: 11px;
      padding: 6px;
    }
    .loading-indicator {
      padding: 4px;
    }
    .x-icon {
      padding: 0 5px;
      font-weight: bold;
      line-height: 26px;
    }

    &[data-indicator-visible='true'] {
      display: inline-block;
    }
  }
}

.combobox .text-field-with-indicator-container {
  .text-field-indicator {
    .loading-indicator {
      padding: 12px 4px;
    }
    .x-icon {
      padding: 5px;
    }
  }
}

.text-field-with-dropdown {
  box-sizing: border-box;
  width: 100%;
}

.text-field-container input[type='text'] {
  width: 100%;
  height: 40px;
}

.hierarchical-select-list {
  width: 100%;
  box-sizing: border-box;

  &:after {
    background-color: #fff;
    border-radius: 3px;
    color: #aaa;
    content: '\25bc';
    font-size: 11px;
    padding: 6px;
    position: absolute;
    top: 4px;
    right: 4px;
  }
}

.with-dropdown .count {
  font-size: smaller;
  float: right;
  color: #999;
}

.tab-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin-bottom: 8px;
  }
  a {
    display: block;
    text-decoration: none;
  }
}

@media (max-width: 480px) {
  .text-field-with-indicator-container {
    .text-field-indicator {
      .loading-indicator {
        padding: 0px;
      }
    }
  }
}

@media (max-width: 320px) {
  .tab-list li {
    display: block;
  }
}

.button-tabs {
  a {
    @extend .round-corners;
    background-color: lighten($secondary-color-light, 55%);
    border: 1px solid lighten($secondary-color-light, 10%);
    box-shadow: inset 0 1px 0 #fff;
    color: lighten($secondary-color-light, 5%);
    font-size: smaller;
    margin-right: 8px;
    padding: 6px 8px;
    text-shadow: 0 1px 0 #fff;
  }

  [data-selected='true'] a {
    background-color: lighten($secondary-color-light, 25%);
    border: 2px solid $secondary-color-light;
    box-shadow: none;
    padding: 5px 7px;
    text-shadow: 0 -1px 0 $secondary-color-light;
    color: #fff;
    box-sizing: border-box;
  }
}

.overflow-textbox {
  border: 1px solid $secondary-color-medium;
  overflow: auto;
  max-height: 300px;
  padding: 10px 20px;
}
