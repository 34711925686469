@import './base.css.scss';

html {
  height: 100%;
}

html body {
  color: $secondary-color;
  font-weight: normal;
  line-height: 1.5;
  width: 100%;
  height: 100%;
}

.page-width {
  max-width: 1366px;
  width: auto;
}

.page-body {
  user-select: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  font-size: 1.75em;
  font-weight: 300;
  line-height: 1.8;
  margin-bottom: 0;
  margin-top: 0;
}

h3 {
  font-size: 1.5em;
  font-weight: normal;
  line-height: 1.8;
  margin-bottom: 24px;
}

.error-content a {
  color: $primary-color;
  background-color: transparent;
}

.call {
  font-size: 0.875em;
  line-height: 1.5;
}

.signature {
  margin-bottom: 40px;
}

header {
  @extend %clearfix;
}

.page-sidebar {
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
  width: 100%;

  li,
  a {
    display: inline-block;
  }

  li {
    margin: 0;
  }

  a {
    padding: 0 15px 0 0;
    text-decoration: none;
    background-color: initial;
    &:hover {
      text-decoration: underline;
    }
  }

  .selected a {
    color: $primary-color;
    &:hover {
      text-decoration: none;
    }
  }

  li:first-child a {
    border-top-width: 0;
  }
  li:last-child a {
    border-bottom-width: 0;
  }
}

.page-content {
  margin-bottom: 60px;

  li {
    margin: 0;
  }

  #table-container {
    overflow-x: auto;
    overflow-y: auto;
    cursor: grab;
    margin-bottom: 30px;
  }

  #coverage-table {
    border-collapse: collapse;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    display: block;
    border: none;
    white-space: nowrap;
    font-size: 0.75em;
    cursor: grab;

    thead {
      width: 100%;
    }

    tr,
    td {
      border: 1px solid #ddd;
      padding: 5px 5px;
      text-align: center;
      color: rgb(76, 76, 76);
    }

    tr:nth-child(odd) {
      background-color: hsl(60, 5%, 96%);
    }

    tr:hover {
      background-color: hsl(355, 27%, 91%);
    }

    tr th {
      background-color: white;
      color: rgb(76, 76, 76);
    }

    th.table-header-top {
      border-bottom: 1px solid rgb(76, 76, 76);
      border-left: 1px solid white;
      padding: 5px 10px;
      background-color: rgb(76, 76, 76);
      color: white;
      position: sticky;
      top: 0;
      z-index: 2;
    }
    .table-header-top-sticky {
      position: relative;
    }

    .table-header-top-sticky th:first-child {
      position: sticky;
      left: -1px;
      top: 0;
      z-index: 3;
    }

    .table-header-left {
      padding: 10px;
      position: sticky;
      left: -1px;
      background-color: white;
      z-index: 1;
    }

    .table-header-top-left {
      border-bottom: 1px solid rgb(76, 76, 76);
      border-left: 1px solid rgb(76, 76, 76);
      background-color: rgb(76, 76, 76);
      color: white;
    }
  }
}

.page-sidebar + .page-content {
  max-width: 100%;
}

#feedback_form_fields_wrapper {
  margin-top: 16px;

  .button.primary {
    margin-right: 12px;
  }
}

textarea#description {
  margin-top: 0;
}

a.go-back {
  float: right;
}

.eula-form-fields {
  margin: 20px 0;
}

@media only screen and (max-width: 800px) {
  .page-content {
    font-size: 14px;
  }
}

@media only screen and (max-width: 640px) {
  .page-sidebar {
    margin-bottom: 20px;
  }

  .page-sidebar,
  .page-content {
    display: block;
    float: none !important;
    width: 100% !important;
  }
}

.question {
  font-weight: bold;
}

.answer {
  margin-bottom: 25px;
}

.question,
.answer {
  .ss-icon {
    background-color: #ddd;
    border-radius: 20px;
    display: inline-block;
    height: 22px;
    position: relative;
    text-align: center;
    top: 3px;
    width: 22px;
  }
}
