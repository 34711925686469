$page-width: 1366px;
$page-width-padding: 20px;

@function image_path($image) {
  @return '../../../assets/images/#{$image}';
}

@function asset_path($image) {
  @return '../../../assets/fonts/#{$image}';
}

// @import 'modern-normalize/modern-normalize.css';
@import './_normalize.css.scss';
@import './_reset.css';
@import './_colors.css.scss';
@import './clearfix.css.scss';
@import './_tipsy.css.scss';
@import './ss_standard.css.scss';
@import './_components.css.scss';
@import './_combobox.css.scss';
@import './_blank_slate.css.scss';
@import './_modal.css.scss';
@import './_notification_banner.css.scss';
@import './_notifications.css.scss';
@import './_announcements.css.scss';
@import './_user_nav.css.scss';
@import './_text.css.scss';
@import './_support.css.scss';

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.page-wrap {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  @extend %clearfix;
}

.wide-header {
  background: $secondary-color-light;
  border-bottom: 1px solid $secondary-color;
  margin-bottom: 20px;
  padding: 12px;
}

$tablet-width: 740px;

@mixin apply-until-tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin hide {
  position: fixed;
  left: -10000px;
}

@mixin sans-serif {
  font-family: 'Helvetica Neue', 'Helvetica ', 'Arial', sans-serif;
}

.sans-serif {
  @include sans-serif;
}

@mixin text-field-type {
  font-size: 16px;
  letter-spacing: normal;
  @include sans-serif;
}

@mixin slideDownAndFadeIn($max-height) {
  @keyframes slideDownAndFadeIn {
    0% {
      display: block;
      height: 0;
      max-height: 0;
      opacity: 0;
    }
    60% {
      height: auto;
      max-height: $max-height;
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin fadeOutAndSlideUp($max-height) {
  @keyframes fadeOutAndSlideUp {
    0% {
      display: block;
      height: auto;
      max-height: $max-height;
      opacity: 1;
    }
    40% {
      display: block;
      height: auto;
      max-height: $max-height;
      opacity: 0;
    }
    100% {
      display: none;
      height: 0;
      max-height: 0;
      opacity: 0;
    }
  }
}

.loading {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E %3Cpath fill='%23000' d='M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z'%3E %3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E %3C/path%3E %3C/svg%3E")
    50% 50% no-repeat;
}
.loading-light {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E %3Cpath fill='%23fff' d='M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z'%3E %3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E %3C/path%3E %3C/svg%3E")
    50% 50% no-repeat;
}

#search-application-container {
  background-color: red;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#enzo-application {
  // min-height: 600px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

body {
  @extend .sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  color: $secondary-color;
  font-size: 16px;
  width: 100%;
  height: 100%;
  margin: 0;
}

header,
footer {
  flex-shrink: 0;
  width: 100%;
}

a,
label {
  cursor: pointer;
}
a {
  color: $secondary-color;
  background-color: $secondary-color-light;
}

a.fake {
  cursor: default !important;
  color: #aaa !important;
}

hr {
  border: solid #ddd;
  border-width: 2px 0 0 0;
}

p {
  line-height: 1.5;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.print-page-dimensions {
  height: 9.5in;
  width: 8.5in;
}

input[type='text'],
input[type='email'],
input[type='tel'] {
  text-overflow: ellipsis;
}

input.text,
textarea.text {
  border: 1px solid #ccc;
  color: #555;
  font-size: 14px;
  padding: 6px 4px;
}

textarea.text {
  margin-top: 8px;
  padding: 6px;
  width: 100%;
  box-sizing: border-box;
}

noscript .enzo-main-logo {
  margin: 0 auto 60px auto;
  width: 66%;
}

.left {
  float: left;
}
.right {
  float: right;
}
.hidden {
  display: none;
}
.no-wrap {
  white-space: nowrap;
}
.round-corners {
  border-radius: 4px;
}

.ui-label {
  font-size: smaller;
  color: $secondary-color;
  text-transform: uppercase;
}

.page-overlay {
  background-color: hsla(0, 0%, 100%, 1);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
}

.page-overlay-content {
  height: 360px;
  margin: auto;
  max-width: 380px;
  padding: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 480px) {
  .page-overlay-content {
    height: auto;
    margin: 0;
    position: static;
  }
}

// TODO: ensure this works
.enzo-main-logo {
  background: url(image_path('logo.svg')) 0 50% no-repeat;
  background-size: 80%;
  display: block;
  height: 70px;
  text-indent: -9999px;
  width: 300px;
  max-width: 100%;
}

.motologic-logo {
  background: left / contain no-repeat url(image_path('motologic-logo.svg'));
  background-size: 80%;
  display: inline-block;
  height: 70px;
  text-indent: -9999px;
  width: 300px;
  max-width: 100%;
}

.driverside-logo {
  background: left / contain no-repeat url(image_path('driverside-logo.svg'));
  background-size: 80%;
  display: inline-block;
  height: 70px;
  text-indent: -9999px;
  width: 300px;
  max-width: 100%;
}

.driverside-logo.frontpage-header {
  display: block;
}

.select-for-mobile {
  display: none;
}

.page-wrapper {
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1400px;
  min-width: 280px;
  text-align: left;

  @extend %clearfix;
}

// Hint: this class is usually used to set the style
// characteristics of the content-containing portions
// of the page. i.e. page width is the width of the content.
.page-width {
  max-width: $page-width;
  max-height: 100%;
  min-width: 260px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $page-width-padding;
  padding-right: $page-width-padding;

  @extend %clearfix;
}

.page-header {
  background-color: $secondary-color-light;
  border-bottom: 1px solid $secondary-color-medium;
  padding-top: 10px;
  padding-bottom: 10px;
}

.page-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.page-container.page-body {
  min-height: 100vh;
}

.page-content,
.page-body {
  @extend %clearfix;
  overflow-x: hidden;
  flex-grow: 1;
}

.page-footer {
  font-size: 12px;
  padding: 20px 0 20px 0;
  width: 100%;

  &,
  a {
    color: #fff;
    background-color: $secondary-color;
  }
  .footer-container {
    @extend .page-width;
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  a:hover {
    color: $primary-color;
  }
  .copyright {
    float: right;
    .version {
      margin-top: 5px;
    }
  }
}

.footer-nav {
  float: left;
  @extend %clearfix;
  ul {
    @extend %clearfix;
    margin-right: 20px;
    margin-bottom: 6px;
  }
  li {
    float: left;
    margin-right: 8px;
    &:after {
      color: #ccc;
      content: '\25cf';
      margin-left: 8px;
    }
    &:last-child:after {
      content: '';
    }
  }
}
@media (max-width: 770px) {
  .motologic-logo {
    background: center / contain no-repeat url(image_path('motologic-logo.svg'));
    background-size: 80%;
    display: block;
    margin: auto;
  }
  .driverside-logo {
    background: center / contain no-repeat
      url(image_path('driverside-logo.svg'));
    background-size: 80%;
    display: block;
    margin: auto;
  }
  .driverside-logo.frontpage-header {
    margin: 0;
  }
}

.driverside-logo.frontpage-header {
  margin: 0;
}

@media (max-width: 480px) {
  .footer-nav {
    float: none;
    ul,
    li {
      margin-right: 0;
    }
    li {
      display: block;
    }
    li:after {
      content: '';
    }
  }

  .page-footer .copyright,
  .footer-nav li {
    float: none;
    text-align: center;
  }

  .copyright {
    margin-top: 20px;
  }
}

// width needs to be 310px for edge browser so it doesn't crop background image.

@supports (-ms-ime-align: auto) {
  .enzo-main-logo {
    width: 310px;
  }
}
