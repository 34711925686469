.blank-slate-container {
  min-width: 180px !important;
}

.blank-slate {
  @extend .round-corners !optional;
  background-color: $secondary-color-light;
  border: none;
  text-align: center;
  padding: 50px;
  margin-top: 20px;

  img {
    margin-bottom: 20px;
  }

  img.checkmark {
    margin-bottom: 0;
  }

  p {
    color: $secondary-color;
  }

  .blank-loading {
    @extend .loading !optional;
    display: inline-block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    padding-left: 48px;
  }
}

@media (max-width: 480px) {
  .blank-slate {
    padding: 20px;
  }
}
