@charset "UTF-8";

/*
* Symbolset
* www.symbolset.com
* Copyright © 2012 Oak Studios LLC
*
* Upload this file to your web server
* and place this within your <head> tags.
* <link href="webfonts/ss-standard.css" rel="stylesheet" />
*/

@font-face {
  font-family: 'SSStandard';
  src: url(asset_path('ss-standard.eot'));
  src: url(asset_path('ss-standard.eot?#iefix')) format('embedded-opentype'),
    url(asset_path('ss-standard.woff')) format('woff'),
    url(asset_path('ss-standard.ttf')) format('truetype'),
    url(asset_path('ss-standard.svg#SSStandard')) format('svg');
  font-weight: normal;
  font-style: normal;
}

/* This triggers a redraw in IE to Fix IE8's :before content rendering. */
html:hover [class^='ss-'] {
  -ms-zoom: 1;
}

.ss-icon,
.ss-icon.ss-standard,
[class^='ss-']:before,
[class*=' ss-']:before,
[class^='ss-'].ss-standard:before,
[class*=' ss-'].ss-standard:before,
[class^='ss-'].right:after,
[class*=' ss-'].right:after,
[class^='ss-'].ss-standard.right:after,
[class*=' ss-'].ss-standard.right:after {
  font-family: 'SSStandard';
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  /*-webkit-font-feature-settings: "liga"; Currently broken in Chrome >= v22. Falls back to text-rendering. Safari is unaffected. */
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

[class^='ss-'].right:before,
[class*=' ss-'].right:before {
  display: none;
  content: '';
}
.ss-search:before,
.ss-search.right:after {
  content: '🔎';
}
.ss-zoomin:before,
.ss-zoomin.right:after {
  content: '';
}
.ss-zoomout:before,
.ss-zoomout.right:after {
  content: '';
}
.ss-view:before,
.ss-view.right:after {
  content: '👀';
}
.ss-move:before,
.ss-move.right:after {
  content: '';
}
.ss-trash:before,
.ss-trash.right:after {
  content: '';
}
.ss-bookmark:before,
.ss-bookmark.right:after {
  content: '🔖';
}
.ss-flag:before,
.ss-flag.right:after {
  content: '⚑';
}
.ss-star:before,
.ss-star.right:after {
  content: '⋆';
}
.ss-halfstar:before,
.ss-halfstar.right:after {
  content: '';
}
.ss-phone:before,
.ss-phone.right:after {
  content: '📞';
}
.ss-send:before,
.ss-send.right:after {
  content: '';
}
.ss-mail:before,
.ss-mail.right:after {
  content: '✉';
}
.ss-inbox:before,
.ss-inbox.right:after {
  content: '📥';
}
.ss-user:before,
.ss-user.right:after {
  content: '👤';
}
.ss-users:before,
.ss-users.right:after {
  content: '👥';
}
.ss-home:before,
.ss-home.right:after {
  content: '⌂';
}
.ss-book:before,
.ss-book.right:after {
  content: '📕';
}
.ss-openbook:before,
.ss-openbook.right:after {
  content: '📖';
}
.ss-notebook:before,
.ss-notebook.right:after {
  content: '📓';
}
.ss-refresh:before,
.ss-refresh.right:after {
  content: '↻';
}
.ss-loading:before,
.ss-loading.right:after {
  content: '';
}
.ss-print:before,
.ss-print.right:after {
  content: '⎙';
}
.ss-fax:before,
.ss-fax.right:after {
  content: '📠';
}
.ss-help:before,
.ss-help.right:after {
  content: '❓';
}
.ss-info:before,
.ss-info.right:after {
  content: 'ℹ';
}
.ss-alert:before,
.ss-alert.right:after {
  content: '⚠';
}
.ss-logout:before,
.ss-logout.right:after {
  content: '';
}
.ss-check:before,
.ss-check.right:after {
  content: '✓';
}
.ss-settings:before,
.ss-settings.right:after {
  content: '⚙';
}
.ss-calendar:before,
.ss-calendar.right:after {
  content: '📅';
}
.ss-directright:before,
.ss-directright.right:after {
  content: '▹';
}
.ss-dropdown:before,
.ss-dropdown.right:after {
  content: '▾';
}
.ss-directleft:before,
.ss-directleft.right:after {
  content: '◃';
}
.ss-delete:before,
.ss-delete.right:after {
  content: '␡';
}
.ss-alertquickspecs:before,
.ss-alertquickspecs.right:after {
  content: '⚠';
}
.ss-dollarsign:before,
.ss-dollarsign.right:after {
  content: '💲';
}
