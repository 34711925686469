.combobox {
  position: relative;
  select {
    appearance: none;
    -webkit-appearance: none;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: none;
  }
  .group-style {
    text-align: center;
    justify-content: space-between;
    text-transform: Capitalize;
    font-weight: bold;
    color: #cc0033;
    margin-bottom: 10px;
  }
  .group-style-makes {
    text-align: left;
    justify-content: space-between;
    text-transform: Capitalize;
    font-weight: bold;
    color: #cc0033;
    margin-bottom: 10px;
  }
  .combobox-year-content__menu {
    width: 725px;
  }
  .combobox-year-content__option {
    width: 100%;
    text-align: center;
    padding: 6px;
  }
  .combobox-year-content__group {
    display: inline-block;
    vertical-align: top;
    width: 11%;
    padding: 10px 0 10px 0;
  }
  .combobox-make-content__menu {
    // make the width dynamic to the number of make options rendered
    width: 550px;
  }
  .combobox-make-content__menu-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 1fr);
    grid-template-rows: repeat(11, auto);
    grid-auto-flow: column;
  }
  .combobox-model-content__menu-list {
    max-height: 350px;
  }
  .combobox-make-content__option {
    width: 100%;
    text-align: left;
    padding: 6px 6px 6px 12px;
  }
  .combobox-model-content__option {
    text-align: left;
    vertical-align: top;
    padding: 6px 6px 6px 12px;
    width: 100%;
  }
  .combobox-model-content__menu-list
    .combobox-model-content__option[data-value^='omv_']:before {
    content: 'OMV';
    background: hsl(0, 0%, 87%);
    border: 1px solid hsl(0, 0%, 73%);
    border-radius: 2px;
    color: hsl(0, 0%, 53%);
    display: block;
    float: right;
    font-size: 11px;
    height: 12px;
    position: relative;
    top: 2px;
    text-align: center;
    width: 32px;
  }
}
.combobox[data-mobile-mode='true'] select {
  display: block;
}
.with-dropdown,
.with-dropdown .dropdown,
.with-dropdown ul {
  @extend .round-corners;
}
.with-dropdown .value,
.with-dropdown input[type='text'] {
  border-radius: 4px 0 0 4px;
  box-sizing: border-box;
  height: 34px;
  overflow: hidden;
  padding: 8px;
  white-space: nowrap;
  width: 100%;
}
.with-dropdown:after,
.with-dropdown .value {
  cursor: pointer;
}
.with-dropdown {
  display: inline-block;
  position: relative;
  text-align: left;
  min-height: 34px;
  background-color: #fff;
  input:focus {
    cursor: text;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
  }
  .dropdown:before,
  .dropdown:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }
  .dropdown:before {
    top: -7px;
    left: 6px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #888;
  }
  .dropdown:after {
    top: -6px;
    left: 7px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $secondary-color-light;
  }
  .dropdown {
    position: absolute;
    left: -1px;
    z-index: 7000;
    top: 48px;
    width: 100%;
    transform: scale(0.75) translateY(-20%);
    transition: 0.3s cubic-bezier(0.4, 0.3, 0, 1.5);
    border: 1px solid hsl(0, 0%, 73%);
    background-color: #fff;
  }
  .dropdown > ul {
    line-height: 2;
    padding: 10px 20px;
    max-height: 250px;
  }
  .list-item-content {
    @extend %clearfix;
    color: $secondary-color;
    cursor: pointer;
    display: block;
    text-decoration: none;
  }
  li:first-child .list-item-content {
    @extend .round-corners;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  li:last-child .list-item-content {
    @extend .round-corners;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.with-dropdown[data-active='false'] .dropdown {
  opacity: 0;
  pointer-events: none;
  width: 100%;
  body.no-csspointerevents & {
    display: none;
  }
}
.with-dropdown[data-active='true'] .dropdown {
  opacity: 1;
  transform: none;
  background-color: $secondary-color-light;
  body.no-csspointerevents & {
    display: block;
  }
}
.with-dropdown .list-item-content:hover,
.with-dropdown [data-selected='true'] .list-item-content {
  color: $primary-color;
}
.hierarchical-select-list .list-item-content:before {
  content: '';
  display: inline-block;
  width: 16px;
}
.hierarchical-select-list .has-children > .list-item-content:before {
  font-family: -apple-system, sans-serif, 'Segoe UI Symbol';
  color: #aaa;
  content: '\25b6';
  font-size: 11px;
}
.hierarchical-select-list .has-children.selected > .list-item-content:before {
  font-family: -apple-system, sans-serif, 'Segoe UI Symbol';
  content: '\25bc';
}
.hierarchical-select-list ul ul .list-item-content:before {
  margin-left: 16px;
}
.hierarchical-select-list ul ul ul .list-item-content:before {
  margin-left: 32px;
}
.hierarchical-select-list ul ul ul ul .list-item-content:before {
  margin-left: 48px;
}
@media (max-width: 760px) {
  .combobox {
    .group-style {
      display: none;
    }
    .combobox-year-content__menu-list {
      max-height: 260px;
    }
    .combobox-year-content__group {
      display: block;
      padding: 0;
      width: 100%;
    }
    .combobox-year-content__menu {
      width: 100%;
    }
    .combobox-year-content__group-heading {
      display: none;
    }
    .combobox-make-content__menu {
      width: 100%;
    }
    .combobox-make-content__menu-list {
      max-height: 260px;
      display: block;
    }
    .combobox-make-content__option {
      width: 100%;
    }
    .combobox-year-content__option {
      text-align: left;
      padding-left: 12px;
    }
    .combobox-model-content__menu {
      width: 100%;
    }
    .combobox-model-content__menu-list {
      max-height: 260px;
    }
    .combobox-model-content__option {
      width: 100%;
    }

    .combobox-year-content__indicators,
    .combobox-make-content__indicators,
    .combobox-model-content__indicators {
      display: none;
    }
  }
}

@media (max-width: 660px) {
  .combobox {
    .combobox-year-content__indicators,
    .combobox-make-content__indicators,
    .combobox-model-content__indicators {
      display: none;
    }
  }
}
