.support-options, fieldset {
  margin-bottom: 40px;
}

.support-options {
  border: 1px solid #ccc;
  border-radius: 3px;
  border-spacing: 0;
  display: table;
  width: 100%;

  .phone-support, .web-support {
    color: $secondary-color;
    display: table-cell;
    text-align: center;
    padding: 20px;
    vertical-align: middle;
    width: 50%;

    &:before {
      @extend .ss-icon;
      display: block;
      font-size: 1.75em;
      color: $primary-color;
    }
  }

  .phone-support {
    border: 1px solid #ccc;
    border-width: 0 1px 0 0;
    position: relative;
    text-decoration: none;

    &:before { content: "\01F4DE"; }

    &:after {
      background-color: #fff;
      content: "or";
      display: block;
      position: absolute;
      top: calc(50% - 14px);
      right: -20px;
      width: 40px;
    }
  }

  .web-support {
    &:before { content: "\002709"; }
  }
}

.support-form {
  .notification.holidays {
    font-size: smaller;
    padding: 20px;
    margin-bottom: 40px;
  }

  fieldset {
    border: solid #ccc;
    border-width: 2px 0 0 0;
    padding: 10px 0 0 0;

    legend {
      background: #fff;
      color: $secondary-color;
      font-size: larger;
      font-weight: bold;
      padding: 0 10px 0 0;
    }
  }

  textarea {
    box-sizing: border-box;
  }

  form > .required-label {
    float: right;
    font-size: smaller;
    font-weight: bold;
    line-height: 36px;
  }

  .car-specific {
    padding-top: 1rem;
    padding-bottom: 1rem;

    .car-specific-toggle {
      padding: 0.5rem;

      a {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        border-radius: 3px;
        border: 1px solid black;
      }

      [data-selected="true"] {
        color: #fff;
        background-color: $primary-color;
      }

      [data-selected="false"] {
        background-color: $secondary-color-medium;;
      }
    }
  }

  .relevant-car, .phone-contact {
    display: table;
    width: 100%;
  }

  .relevant-car-name, .relevant-car-engine { display: table-cell; }
  .relevant-car-name { width: 60%; }
  .relevant-car-engine { width: 20%; padding-left: 5px; }
  .relevant-car-details { display: table-cell; width: 20%; padding-left: 5px; }

  .phone-contact-name, .phone-contact-number { display: table-cell; }
  .phone-contact-number { width: 40%; padding-right: 5px; }
  .phone-contact-name { width: 60%; padding-left: 5px; }

  .contact-label {
    border-top: 1px solid #ddd;
    display: block;
    margin-top: 20px;
    padding: 16px 0;
  }

  .notification-banner {
    margin-bottom: 40px;
  }

  button {
    width: 150px;
  }

  .loading {
    @extend .loading-light;
    background-size: contain !important;
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
  }
}

.support-form .car-form {
  button.cancel-edit-mode {
    display: none;
  }

  .car-form-inputs {
    margin: 0;
    padding: 10px 0 10px 0;
    list-style: none;
    width: 100%;
    box-sizing: border-box;
    @extend %clearfix;

    & > li {
      float: left;
      padding: 0 5px;
      box-sizing: border-box;
    }

    & > li:first-child { padding-left: 0; }
    & > li:last-child { padding-right: 0; }

    .year { width: 25%; }
    .make { width: 25%; }
    .model { width: 50%; }

    .model .dropdown a[data-value^='omv_']:before {
      content: "OMV";
      background: hsl(0, 0%, 87%);
      border: 1px solid hsl(0, 0%, 73%);
      border-radius: 2px;
      color: hsl(0, 0%, 53%);
      display: block;
      float: right;
      font-size: 11px;
      height: 12px;
      position: relative;
      top: 2px;
      text-align: center;
      width: 32px;
    }

    li.model {
      padding-right: 0px;
    }

    .vin {
      display: none;
    }

    .ymm-vin-division {
      display: none;
    }
  }
}

#feedback-thankyou {
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 4px;
  margin: 0 auto;
  width: 85%;

  & > h2, & > h3 {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #bbb;
    border-radius: 4px 4px 0 0;
    margin: 0;
    padding: 10px 20px;
    background-color: #eee;
    text-shadow: 0 -1px 0 #fff;
    background: linear-gradient(#f3f3f3, #e3e3e3);
  }

  & > div, & > form, & > table {
    padding: 15px 20px;
  }
}

#feedback-thankyou p,
#feedback-thankyou table {
  margin-bottom: 16px;
}

#feedback-thankyou div {
  font-size: 16px;
  line-height: 24px;
}

#feedback-thankyou .signature {
  color: #999;
  margin: 12px 0 24px 0;
}

#feedback-thankyou table {
  font-size: smaller;
  width: 100%;

  th { text-align: left; }
}

@media (max-width: 500px) {
  .support-form {
    .phone-support, .web-support,
    .relevant-car-name, .relevant-car-engine,
    .phone-contact-number, .phone-contact-name {
      display: block;
      padding: 0;
      width: 100%;
    }

    .phone-support, .web-support { padding: 30px 0; }
    .phone-support {
      border-width: 0 0 1px 0;

      &:after {
        top: auto;
        right: calc(50% - 20px);
        bottom: -10px;
        left: auto;
      }
    }
  }
}
